.about {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}
p {
  font-size: calc(0.75em + 1vmin);
}

.about__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.about__wrapper {
  position: relative;
  margin: 50px 0 45px;
}
