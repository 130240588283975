.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:first baseline;
    align-items: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    object-fit: contain;
}

.hero-container > img {
  width: 250px;
  height: 250px;
  margin-top: 4%;
  margin-bottom: 4%;
}
  
  .hero-container > h1 {
    color: #fff;
    font-size: 600%;
    margin-top: -5%;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 200%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .chapter {
    font-size: 1px;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  .hero-container > .chapter {
    font-size: 150%;
  }

  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 500%;
      margin-top: 10%;
    }
    
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 250%;
      margin-top: 10%;
    }
  
    .hero-container > p {
      font-size: 100%;
      text-align: center;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .hero-container > .chapter {
      font-size: 80%;
    }

    .btn {
      width: 100%;
    }

    .hero-container > img {
      width: 200px;
      height: 200px;
      margin-top: 10%;
      margin-bottom: 4%;
    }
  }

  @media only screen and (max-width: 375px) {
    .hero-container > h1 {
      font-size: 200%;
      margin-top: 10%;
    }
  
    .hero-container > p {
      font-size: 100%;
    }

    .hero-container > .chapter {
      font-size: 80%;
    }

    .hero-container > img {
      width: 150px;
      height: 150px;
      margin-top: 10%;
      margin-bottom: 4%;
    }
  }