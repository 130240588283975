* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home{
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }

  .whatsapp-icon {
    position: fixed;
    float: right;
    bottom: 20px; /* Adjust the vertical position */
    right: 20px; /* Adjust the horizontal position */
    z-index: 999;
    font-size: 25px;
    color: #25d366;
    display: inline-block;
    transition: all 400ms;
  }

  .fa-stack-1x {
    color:white;
  }
