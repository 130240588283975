
.mission-and-vision-container {
    height: 50vh;
    width: 100%;
}
  
.mission_and_vision {
  padding: 4rem;
  background: #fff;
}

p {
  font-size: calc(0.75em + 1vmin);
}

h1 {
  text-align: center;
}

.mission_and_vision__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.mission_and_vision__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

